import api from './api'
import globalLit from './globalLit'
export default { 
  add(data) {
    return api.execute(`post`,`/moderator/services`, data)
  },
  update(data) {
    return api.execute(`put`,`/moderator/services`, data)
  },
  get() {
    return api.execute('get', `/moderator/services`)
  },
  remove(data) {
    return api.execute(`delete`,`/moderator/services`, data)
  },
  getMonthlyServices(data) {
    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/list/services/monthly`, payload)
  },
  addServiceMapping(data){
    return api.execute(`post`,`/moderator/smap`,data)
  },
  getServiceMapping(){
    return api.execute(`get`,`/moderator/smap`,)
  },
  deleteServiceMapping(payload){
    return api.execute(`delete`,`/moderator/smap`,payload)
  },
  editServiceMapping(data){
    return api.execute(`put`,`/moderator/smap`,data)
  },

  getAdNetworks(data){
    data = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/adnetworks`,data)
  },

  getChennel(data){
    data = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/channel`,data)
  },

  getAdNetworksByDate(data){
    data = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/adnetworks/days`,data)
  },

  getDestUrls(data){
    data = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/desturl`,data)
  },
  getUniqueHosts(data){
    data = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/hosts`,data)
  },
  
  getUniqueUrls(data){
    data = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/urls`,data)
  },

  getServiceInfo(data){
    return api.execute(`post`,`/serviceinfo`,data)
  }

}